<template>
  <div class="m-2">
    <b-row>
      <b-col md="6">
        <b-card title="Merchant">
          <dl class="row">
            <dt class="col-sm-4">
              Merchant Name :
            </dt>
            <dd class="col-sm-8">
              {{ accData.merchant_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              Merchant NO. :
            </dt>
            <dd class="col-sm-8">
              {{ accData.merchant_no }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              APP Name :
            </dt>
            <dd class="col-sm-8">
              {{ accData.app_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              APP ID :
            </dt>
            <dd class="col-sm-8">
              {{ accData.app_id }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Account">
          <dl class="row">
            <dt class="col-sm-4">
              ID :
            </dt>
            <dd class="col-sm-8">
              {{ accData.id }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              ACCOUNT NUMBER :
            </dt>
            <dd class="col-sm-8">
              {{ accData.account_number }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              PROVIDER :
            </dt>
            <dd class="col-sm-8">
              {{ accData.provider }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              NOTIFY URL :
            </dt>
            <dd class="col-sm-8">
              {{ accData.notify_url }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              STATUS :
            </dt>
            <dd class="col-sm-8">
              {{ accData.status }}
            </dd>
          </dl>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Customer">
          <dl class="row">
            <dt class="col-sm-4">
              BUYER ID :
            </dt>
            <dd class="col-sm-8">
              {{ accData.buyer_id }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              NAME :
            </dt>
            <dd class="col-sm-8">
              {{ accData.buyer_name }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              EMAIL :
            </dt>
            <dd class="col-sm-8">
              {{ accData.buyer_email }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-4">
              IDENTITY :
            </dt>
            <dd class="col-sm-8">
              {{ accData.buyer_identity }}
            </dd>
          </dl>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard,
} from 'bootstrap-vue'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import useMerchantsJs from '../merchants/merchants'

const {
  fetchMerchantInfo,
} = useMerchantsJs()

export default {
  components: {
    BRow,
    BCol,
    BCard,
  },
  props: {
    accData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      merchantInfo: {},
    }
  },
  created() {
    fetchMerchantInfo(this, this.accData.merchant_no, info => {
      this.initMerchantInfo(info)
    })
  },
  methods: {
    initMerchantInfo(info) {
      this.merchantInfo = {
        logoUrl: info.logo_url,
        merchantNo: info.merchant_no,
        merchantName: info.merchant_name,
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
