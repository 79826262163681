<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>

        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date Mode</label>
          <v-select
            v-model="dateModeFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="dateModeOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Timezone</label>
          <v-select
            v-model="tzFilter"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="tzOptions"
            :clearable="false"
            :searchable="false"
            class="w-100"
            :reduce="val => val.value"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label>Date Range</label>
          <date-picker
            v-model="dateRange"
            value-type="format"
            format="YYYY-MM-DD HH:mm:ss"
            type="datetime"
            range
            confirm
            :clearable="false"
            :shortcuts="shortcuts"
          />
        </b-col>
        <b-col
          cols="12"
          md="4"
          class="mb-md-0 mb-2"
        >
          <label />
          <b-button
            class="mt-2"
            variant="primary"
            @click="doSearch"
          >
            Search
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'
import moment from 'moment-timezone'
import useRecurringJs from './virtualaccs'

const {
  fetchMerchants,
  fetchMerchantApps,
  fetchChannelFilters,
  fetchPayChannelListDetail,
} = useRecurringJs()

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
    DatePicker,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      params: {
        merchant_no: '',
        app_id: '',
        method: '',
        trade_status: '',
        start_time: '',
        end_time: '',
        channel: '',
      },
      accountId: '',
      payChannelList: [],

      merchantFilter: '',
      merchantOptions: [{ label: 'All', value: '' }],

      appFilter: '',
      appOptions: [{ label: 'All', value: '' }],

      methodFilter: { type_id: '', type_name: '', type_desc: 'All' },
      methodOptions: [],

      channelFilter: { label: 'All', value: '' },
      channelOptions: [],

      statusFilter: '',
      statusOptions: [
        { label: 'All', value: '' },
        { label: 'Active', value: '1' },
        { label: 'Canceled', value: '0' },
      ],

      dateModeFilter: '1',
      dateModeOptions: [
        { label: 'Create Time', value: '1' },
      ],

      searchModeFilter: '1',
      searchModeOptions: [
        { label: 'BuyerId', value: '1' },
        { label: 'AccountNumber', value: '2' },
        { label: 'ID', value: '3' },
      ],

      tzFilter: 'Etc/GMT',
      tzOptions: [
        // { label: 'Etc/GMT-12', value: 'Etc/GMT-12' },
        // { label: 'Etc/GMT-11', value: 'Etc/GMT-11' },
        // { label: 'Etc/GMT-10', value: 'Etc/GMT-10' },
        // { label: 'Etc/GMT-9', value: 'Etc/GMT-9' },
        { label: 'Asia/Shanghai', value: 'Etc/GMT-8' },
        // { label: 'Etc/GMT-7', value: 'Etc/GMT-7' },
        // { label: 'Etc/GMT-6', value: 'Etc/GMT-6' },
        // { label: 'Etc/GMT-5', value: 'Etc/GMT-5' },
        // { label: 'Etc/GMT-4', value: 'Etc/GMT-4' },
        // { label: 'Etc/GMT-3', value: 'Etc/GMT-3' },
        // { label: 'Etc/GMT-2', value: 'Etc/GMT-2' },
        // { label: 'Etc/GMT-1', value: 'Etc/GMT-1' },
        { label: 'Etc/GMT', value: 'Etc/GMT' },
        // { label: 'Etc/GMT+1', value: 'Etc/GMT+1' },
        // { label: 'Etc/GMT+2', value: 'Etc/GMT+2' },
        { label: 'America/Sao_Paulo', value: 'Etc/GMT+3' },
        // { label: 'Etc/GMT+4', value: 'Etc/GMT+4' },
        // { label: 'Etc/GMT+5', value: 'Etc/GMT+5' },
        { label: 'America/Mexico_City', value: 'Etc/GMT+6' },
        // { label: 'Etc/GMT+7', value: 'Etc/GMT+7' },
        // { label: 'Etc/GMT+8', value: 'Etc/GMT+8' },
        // { label: 'Etc/GMT+9', value: 'Etc/GMT+9' },
        // { label: 'Etc/GMT+10', value: 'Etc/GMT+10' },
        // { label: 'Etc/GMT+11', value: 'Etc/GMT+11' },
        // { label: 'Etc/GMT+12', value: 'Etc/GMT+12' },
      ],

      searchKey: '',

      dateRange: [],
      shortcuts: [
        {
          text: 'Today',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Yesterday',
          onClick() {
            const end = moment().subtract(1, 'days').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 7 Days',
          onClick() {
            const end = moment().endOf('day')
              .toDate()
            const start = moment().subtract(6, 'days').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'This Month',
          onClick() {
            const end = moment().endOf('month').endOf('day')
              .toDate()
            const start = moment().startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last Month',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(1, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
        {
          text: 'Last 3 Months',
          onClick() {
            const end = moment().subtract(1, 'month').endOf('month').endOf('day')
              .toDate()
            const start = moment().subtract(3, 'month').startOf('month').startOf('day')
              .toDate()
            return [start, end]
          },
        },
      ],
    }
  },
  computed: {
    channelDisabled() {
      return this.methodFilter === '' || this.methodFilter.type_id === ''
    },
  },
  watch: {
    dateRange: function formatWithTz(object) {
      const startTime = object[0]
      const endTime = object[1]
      const startTzTime = moment.tz(startTime, this.tzFilter)
      const endTzTime = moment.tz(endTime, this.tzFilter)
      const startTimeStr = startTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')
      const endTimeStr = endTzTime.clone().tz('UTC').format('YYYY-MM-DD HH:mm:ss')

      const dateArray = new Array(0)
      dateArray.push(startTimeStr, endTimeStr)
      this.$emit('update:dateRange', dateArray)
    },
    merchantFilter: function fillApps(merchantNo) {
      this.appOptions = [{ label: 'All', value: '' }]
      fetchMerchantApps(this, merchantNo, apps => {
        this.appOptions = apps
      })
    },
    methodFilter: function resetChannel(method) {
      const defaultChannel = { label: 'All', value: '' }
      const channels = []
      channels.push(defaultChannel)

      this.payChannelList.forEach(item => {
        if (method === item.type_name) {
          const cl = {
            label: item.cname,
            value: item.cid,
          }
          channels.push(cl)
        }
      })
      this.channelOptions = channels
      this.channelFilter = ''
    },
  },
  created() {
    fetchMerchants(this, merchants => {
      this.merchantOptions = merchants
    })
    fetchChannelFilters(this, filters => {
      this.methodOptions = filters.methods
      this.methodFilter = ''
    })
    fetchPayChannelListDetail(this, list => {
      this.payChannelList = list
    })

    this.dateRange = [moment().startOf('month').startOf('day').format('YYYY-MM-DD HH:mm:ss'), moment().endOf('day').format('YYYY-MM-DD HH:mm:ss')]
  },
  methods: {
    genParams() {
      let [start, end] = this.dateRange

      start = moment.tz(moment.tz(start, this.tzFilter), 'UTC').format('YYYY-MM-DD HH:mm:ss')
      end = moment.tz(moment.tz(end, this.tzFilter), 'UTC').format('YYYY-MM-DD HH:mm:ss')

      const params = {
        merchant_no: this.merchantFilter,
        app_id: this.appFilter,
        status: this.statusFilter,
        start_time: start,
        end_time: end,

        date_mode: this.dateModeFilter,
        search_mode: 3,
        search_key: this.data.id,
        page_num: 1,
        zone_id: encodeURIComponent(this.tzFilter),
      }

      return params
    },
    doSearch() {
      const params = this.genParams()
      this.$emit('search', params)
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .mx-datepicker-range {
    width: 100% !important;
  }
  .mx-input {
    height: 37px;
  }
</style>
