<template>
  <component :is="accData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="accData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No Account found. Check
      </div>
    </b-alert>

    <b-tabs
      v-if="accData"
      pills
    >
      <!-- Tab: Account -->
      <b-tab active>
        <template #title>
          <feather-icon
            icon="UserIcon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Account</span>
        </template>
        <virtual-account-detail
          :acc-data="accData"
          class="mt-2 pt-75"
        />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon
            icon="Share2Icon"
            size="16"
            class="mr-0 mr-sm-50"
          />
          <span class="d-none d-sm-inline">Transactions</span>
        </template>
        <virtual-account-transactions :acc-data="accData" class="mt-2 pt-75" />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import {
  BTab, BTabs, BCard, BAlert, BLink,
} from 'bootstrap-vue'
import VirtualAccountDetail from './VirtualAccountDetail.vue'
import VirtualAccountTransactions from './VirtualAccountTransactions.vue'

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    VirtualAccountDetail,
    VirtualAccountTransactions,
  },
  props: {
    accData: {
      type: Object,
      required: true,
    },
  },
}
</script>
