<template>

  <div>
    <b-modal
      :visible="isDetailModalActive"
      title="Account Detail"
      size="xl"
      hide-footer
      centered
      @change="(val) => isDetailModalActive = val"
    >
      <virtual-account :acc-data="accountDetail" />
    </b-modal>
    <!-- Filters -->
    <virtual-account-list-filters
      @search="search"
    />

    <!-- Table Container Card -->
    <b-card no-body>

      <b-table
        class="position-relative"
        responsive
        :fields="transactionsTableFields"
        :items="transactions"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(id)="data">
          <b-link
            class="font-weight-bold d-block text-nowrap"
            @click="accountDetails(data.item)"
          >
            {{ data.item.id }}
          </b-link>
        </template>
        <template #cell(merchant)="data">
          {{ data.item.merchant_name }}
          <small class="text-muted">@{{ data.item.merchant_no }}</small>
        </template>

        <template #cell(app)="data">
          {{ data.item.app_name }}
          <small class="text-muted">@{{ data.item.app_id }}</small>
        </template>

        <template #cell(account_number)="data">
          {{ data.item.account_number }}
        </template>

        <template #cell(create_time)="data">
          {{ dateFormat(data.item.create_time) }}
        </template>
        <template #cell(update_time)="data">
          {{ dateFormat(data.item.update_time) }}
        </template>
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BBadge, BCard, BRow, BCol, BTable, BPagination, BLink, BOverlay,
} from 'bootstrap-vue'
import moment from 'moment-timezone'
import { showToast } from '@/libs/tool'
import VirtualAccountListFilters from './VirtualAccountListFilters.vue'
import useVirtualAccJs from './virtualaccs'
import VirtualAccount from './VirtualAccount.vue'

const {
  fetchMerchantVirtualAccounts,
} = useVirtualAccJs()

export default {
  components: {
    VirtualAccountListFilters,
    BBadge,
    BCard,
    BRow,
    BLink,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    VirtualAccount,
  },
  data() {
    return {
      searchRequest: {},
      transactions: [],
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      perPage: 10,
      isDetailModalActive: false,
      transactionsTableFields: [
        'id',
        'merchant',
        'app',
        { key: 'account_number', label: 'ACCOUNT NUMBER' },
        { key: 'provider', label: 'PROVIDER' },
        { key: 'status', label: 'STATUS' },
        { key: 'buyer_id', label: 'BUYER ID' },
        { key: 'create_time', label: 'CREATE TIME' },
        { key: 'update_time', label: 'UPDATE TIME' },
      ],
      currentPage: 1,
      totalTransactions: 0,
      tzSelected: 'Etc/GMT+3',
      loading: false,
      accountDetail: {},
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.searchRequest.page_num = pageNum
      this.search(this.searchRequest)
    },
  },
  methods: {
    accountDetails(item) {
      this.accountDetail = item
      this.isDetailModalActive = true
    },
    resolveStatusVariant(status) {
      switch (status) {
        case 'ACTIVE':
          return 'success'
        case 'CANCELED':
          return 'warning'
        default:
          return 'secondary'
      }
    },
    dateFormat(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.tz(value, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    convertUnixTime(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.unix(value).utc(), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    search(params) {
      this.loading = true
      this.transactions = []
      this.searchRequest = params
      this.tzSelected = decodeURIComponent(params.zone_id)
      fetchMerchantVirtualAccounts(
        this.searchRequest,
        transactions => {
          this.transactions = transactions.list
          this.totalTransactions = transactions.total
          this.dataMeta.from = this.currentPage * 10 - (10 - 1)
          this.dataMeta.to = this.totalTransactions - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalTransactions
          this.dataMeta.of = this.totalTransactions

          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `Fetch Virtual Account configured range error with ${fail}.`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
