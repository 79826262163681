<template>
  <div>
    <virtual-account-transaction-filter
      :data="accData"
      @search="search"
    />
    <b-card no-body>
      <b-table
        class="position-relative"
        responsive
        :fields="transactionsTableFields"
        :items="transactions"
        primary-key="trade_no"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >
        <template #cell(trade_no)="data">
          <b-link
            :to="{ name: 'transactions-detail', query:{trade_no: data.item.trade_no, app_id: data.item.app_id , out_trade_no: data.item.out_trade_no , zone_id: tzSelected} }"
            target="_blank"
            class="font-weight-bold d-block"
          >
            {{ data.item.trade_no }}
          </b-link>
        </template>
        <template #cell(out_trade_no)="data">
          {{ data.item.out_trade_no }}
        </template>
        <template #cell(merchant)="data">
          {{ accData.merchant_name }}
          <small class="text-muted">@{{ data.item.merchant_no }}</small>
        </template>

        <template #cell(app)="data">
          {{ accData.app_name }}
          <small class="text-muted">@{{ data.item.app_id }}</small>
        </template>

        <template #cell(pay_amount)="data">
          {{ data.item.pay_currency }}  {{ data.item.pay_amount }}
        </template>
        <template #cell(account_number)="data">
          {{ data.item.account_number }}
        </template>
        <template #cell(create_time)="data">
          {{ dateFormat(data.item.create_time) }}
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalTransactions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
    <b-overlay
      :show="loading"
      no-wrap
    />
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BTable, BPagination, BLink, BOverlay,
} from 'bootstrap-vue'

import moment from 'moment-timezone'
import { showToast } from '@/libs/tool'
import VirtualAccountTransactionFilter from './VirtualAccountTransactionFilter.vue'
import useVirtualAccJs from './virtualaccs'

const {
  fetchVirtualAccountTransactions,
} = useVirtualAccJs()
export default {
  components: {
    BCard,
    BRow,
    BLink,
    BCol,
    BTable,
    BPagination,
    BOverlay,
    VirtualAccountTransactionFilter,
  },
  props: {
    accData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      searchRequest: {},
      currentPage: 1,
      totalTransactions: 0,
      perPage: 10,
      tzSelected: 'Etc/GMT+3',
      loading: false,
      transactions: [],
      transactionsTableFields: [
        { key: 'trade_no', label: 'Transaction ID' },
        { key: 'out_trade_no', label: 'Merchant Reference' },
        'merchant',
        'app',
        { key: 'account_number', label: 'ACCOUNT NUMBER' },
        { key: 'pay_amount', label: 'AMOUNT' },
        { key: 'buyer_id', label: 'BUYER ID' },
        { key: 'create_time', label: 'CREATE TIME' },
      ],
    }
  },
  watch: {
    currentPage: function refetchSearchData(pageNum) {
      this.searchRequest.page_num = pageNum
      this.search(this.searchRequest)
    },
  },
  created() {
    this.search({
      date_mode: '1',
      search_mode: 3,
      search_key: this.accData.id,
      page_num: 1,
      zone_id: 'Etc%2FGMT',
    })
  },
  methods: {
    dateFormat(value) {
      if (!value) {
        return ''
      }
      return moment.tz(moment.tz(value, 'UTC'), this.tzSelected).format('YYYY-MM-DD HH:mm:ss')
    },
    search(params) {
      this.loading = true
      this.transactions = []
      this.searchRequest = params
      this.tzSelected = decodeURIComponent(params.zone_id)
      fetchVirtualAccountTransactions(
        this.searchRequest,
        transactions => {
          this.transactions = transactions.list
          this.totalTransactions = transactions.total
          this.dataMeta.from = this.currentPage * 10 - (10 - 1)
          this.dataMeta.to = this.totalTransactions - this.currentPage * 10 > 0 ? this.currentPage * 10 : this.totalTransactions
          this.dataMeta.of = this.totalTransactions
          this.loading = false
        },
        fail => {
          this.loading = false
          showToast(this, 'Warning', `Fetch Virtual Account configured range error with ${fail}.`)
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
